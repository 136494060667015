<template lang="">
    <div class="field">
        <div class="grid formgrid mt-6 px-4">
            <div class="field col-6">
                <label class="font-medium">Novo Contrato</label>
                <div v-for="cadastro in formData.cadastros" :key="cadastro.id">
                    <p class="my-2 p-2 border-round-xs surface-ground">{{ cadastro.descricao }}</p>
                </div>
            </div>
            <div class="col-12">
                <div class="col-6 flex justify-content-end">
                    <Button style="font-size: 13px" label="Download planilha" class="p-button-link w-9rem p-0" @click="downloadPlanilha()" />
                </div>
            </div>
            <div class="col-12 p-3">
                <div class="flex justify-content-end">
                    <div class="flex align-items-center justify-content-center w-50rem font-bold text-white">
                        <Button label="Voltar" class="p-button-outlined p-button-primary mr-2 w-15rem" @click="prevPage()" />
                        <Button label="Próximo" class="p-button p-button-primary mr-2 w-15rem" @click="nextPage()" />
                        <Button label="Salvar etapa" class="p-button p-button-success w-15rem" @click="save()" :loading="submitted" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getClientBase } from '@/services/http';
import { read, writeFileXLSX } from 'xlsx';
import { showError, showSuccess } from '../../../utils/Toast';

export default {
    props: {
        formData: Object
    },
    data() {
        return {
            submitted: false
        };
    },
    methods: {
        async downloadPlanilha() {
            const file = await (await fetch(`${window.location.origin}/planilha.xlsx`)).arrayBuffer();
            const workbooks = read(file);

            const workbook1 = {
                ...workbooks,
                SheetNames: this.formData.cadastros.map((cadastro) => {
                    return cadastro.descricao.toUpperCase();
                }),
                Workbook: {
                    ...workbooks.Workbook,
                    Sheets: this.formData.cadastros.map((cadastro) => {
                        return cadastro.descricao.toUpperCase();
                    })
                }
            };
            writeFileXLSX(workbook1, 'planilha-importacao.xlsx');
        },
        nextPage() {
            this.$emit('next-page', {
                formData: { step: 4, link: [...this.formData.link, '/importacao-cadastro-estrutura/download-planilha'] },
                pageIndex: 2
            });
        },
        prevPage() {
            this.$emit('prev-page', { formData: { pageIndex: 2, step: 2 } });
        },
        async save() {
            try {
                this.submitted = true;
                const data = {
                    customerId: this.formData.clienteId,
                    tipoImportacao: this.formData.tipoImportacao,
                    etapaProcesso: 'D',
                    cadastros: this.formData.cadastros.map((cadastro) => {
                        return cadastro.id;
                    })
                };

                if (Number(this.$route.params.id) > 0) {
                    await getClientBase().patch(`/importacao-estrutura/${this.$route.params.id}`, { ...data, id: Number(this.$route.params.id) });
                } else {
                    await getClientBase().post('/importacao-estrutura', data);
                }

                showSuccess(this.$toast, 'Importação salva com sucesso!');
                this.submitted = false;
                this.$router.push('/importacao-cadastro-estrutura');
            } catch (error) {
                showError(this.$toast, 'Problemas ao salvar.');
                this.submitted = false;
            }
        }
    }
};
</script>

<style scoped lang="scss">
::v-deep(.tipo-importacao) {
    border-radius: 6px;
}
</style>
